@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Signika Negative', sans-serif !important;
    min-width: 350px;
}

.badge {
    &__small {
        width: 80px;
    }
    width: 120px;
    transform: skew(-15deg);
    background-color: #0069b4ff;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
    &__text {
        font-size: 1rem;
        text-align: center;
        line-height: 1;
        transform: skew(15deg);
        text-transform: uppercase;
        letter-spacing: 0.75px;
        font-family: 'Signika Negative', sans-serif;
        color: white;
        //display: block;
    }
}

.select-icon {
    position: absolute;
    right: 0;
    margin-right: 20px;
    pointer-events: none;
    font-size: 15px;
    top: 18px;
    //&:after{
    //  content: "\1F893";
    //  position: absolute;
    //  right: 0;
    //  margin-right: 20px;
    //  pointer-events: none;
    //  font-size: 25px;
    //  top:18px
    //}
}

.pricing-card {
    display: grid;
    grid-template-rows: max-content min-content min-content;
    grid-row-gap: 20px;
    background: #fff;
    width: 300px;
    //padding: 32px 28px;
    border-radius: 15px;
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.1), 0 6px 20px -3px rgba(0, 0, 0, 0.2);
    @apply py-10 px-10;
    &--active {
        box-shadow: 0 0 8px -2px rgba(#0069b4ff, 0.3), 0 6px 20px -3px rgba(#0069b4ff, 0.3) !important;
    }
    &__header {
        img {
        }
        display: grid;
        grid-template-rows: repeat(3, min-content);
        row-gap: 5px;
    }
    &__content {
        display: grid;
        row-gap: 15px;
    }
    &__footer {
        border-top: 1px solid #eaeaeaff;
    }
}

.custom-select {
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    outline: 0;
    background: none;
    border: 0;
}

.options-list {
    padding: 20px 28px;
    > li:not(:last-child) {
        margin-bottom: 28px;
    }
    > li {
        &:before {
            content: '\2713';
            position: absolute;
            width: 10px;
            color: #1f83cd;
            left: -32px;
        }
    }
}

.nested-list {
    padding: 0px 10px;
    > li {
        &:before {
            content: '\2022';
            position: absolute;
            width: 10px;
            color: black;
            left: 0px;
        }
    }
}

.text-animated-one {
    -webkit-animation: fade-in 4s infinite;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-end: 1;
}

.text-animated-two {
    -webkit-animation: fade-in 4s infinite;
    opacity: 0;
    animation-delay: 2s;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-end: 1;
}

@keyframes fade-in {
    0%,
    20% {
        opacity: 0;
        transform: translateY(50px);
        //top: 50px;
    }
    30%,
    70% {
        opacity: 1;
        transform: translateY(0px);
    }
    80%,
    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

.border-lightgray {
    border: 2px solid #e6e6e6 !important;
}

.border-error {
    border: 2px solid #e3342f !important;
}

.font {
    &--darkgray {
        color: #4d4d4d;
    }
    &--gray {
        color: #9b9b9b;
    }
    &--lightgray {
        color: #cbcbcb;
    }
    &__open-sans {
        font-family: 'Open Sans', sans-serif;
    }
    &__siginika {
        font-family: 'Signika Negative', sans-serif;
    }
    &__work-sans {
        font-family: 'Work Sans', sans-serif;
    }
}

.big-font {
    font-size: 1.25rem;
}

.input {
    &__login {
    }
}

.mb--2 {
    margin-bottom: -10px;
}

.bg-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @apply lg:w-6/12 mdlg:hidden;
    &--login {
        // height: 520px;
        // width: 690px;
        background-image: url('./assets/images/login-bg.svg');
    }
    &--profile {
        background-image: url('./assets/images/lady_right.png');
    }
    &--register {
        // height: 616px;
        background-image: url('./assets/images/register-bg.svg');
    }
    &--thank-you {
        background-image: url('./assets/images/lady_ok.png');
        background-position: center;
    }
}

.link {
    color: rgb(31, 131, 205);
    &:hover {
        color: #235ebf;
        text-decoration: underline;
    }
}

$box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1), 3px 3px 20px rgba(0, 0, 0, 0.15);

.account {
    z-index: 99;
    box-shadow: $box-shadow;
    width: 700px !important;
    grid-template-rows: min-content 1fr min-content;
    @apply py-10 px-10 rounded-lg bg-white border-gray grid gap-y-6;
}

@keyframes moveLabel {
    0% {
        transform: translateY(0);
        //@apply text-primary-600 text-sm
    }

    100% {
        transform: translateY(-26px);
        //@apply bg-white px-2
    }
}

input + label {
    order: -1;
    position: absolute;
    left: 6px;
    top: 14px;
    transition: all 0.2s, background-color 0.01s, padding-left 0.01s, padding-right 0.01s ease-in;
    pointer-events: none;
    @apply text-gray-400 px-2;
}

input:focus::placeholder {
    display: none;
    color: transparent;
}

input:focus,
input:focus + label {
    @apply border-primary;
}

input:focus + label {
    @apply text-primary-600;
}

input:focus + label,
input:not([value='']) + label {
    transform: translateY(-20px);
    @apply text-xs bg-white;
}

//@screen xs {
//
//  .header{
//    grid-template-rows: repeat(2, max-content);
//  }
//}

@screen xxs {
    //.social-media-icons{
    //  @apply flex-row justify-center #{!important};
    //  a {
    //    margin-top: unset;
    //  }
    //}
    .pricing-card {
        width: 375px;
    }
}

@screen sm {
    .pricing-card {
        width: 400px;
    }
}

@screen mdlg {
    .card-deck {
        @apply grid-cols-pricing-cards-xs gap-x-5 gap-y-5 #{!important};
    }
    .page-container {
        @apply justify-center #{!important};
    }
    .profile-container {
        @apply justify-center #{!important};
    }
    .thank-you-container {
        height: 100% !important;
    }

    //.header{
    //  grid-template-rows: repeat(1, max-content) !important;
    //  @apply gap-x-3;
    //}
}

@screen lgxl {
    .page-container {
        @apply lg:w-6/12 justify-start #{!important};
    }
    .profile-container {
        @apply lg:w-6/12 justify-start mr-20 #{!important};
    }
    .prof-container {
        @apply w-full justify-start mr-20 #{!important};
    }
}

@screen lg {
    .main-container {
        @apply pt-11 #{!important};
    }

    //.header{
    //  grid-template-rows: repeat(1, max-content) !important;
    //  @apply gap-x-3;
    //}
}

@screen xl {
    .pricing-card {
        width: 410px;
    }
    .card-deck {
        @apply grid-cols-pricing-cards-lg gap-x-5 gap-y-5 #{!important};
    }
    .header {
        grid-template-rows: repeat(1, max-content) !important;
        @apply gap-x-3;
    }
}

.header {
    grid-template-rows: repeat(2, max-content);
    @apply grid justify-center items-center grid-flow-col gap-y-3 justify-items-center mb-3;
}

.animation-container {
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 200px;
    align-content: center;
    justify-content: center;
    color: white;
    width: 200px;
    border-radius: 15px;
    height: 45px;
    @apply bg-primary-600;
}

.card-deck {
    row-gap: theme('spacing.4');
    @apply grid grid-cols-pricing-cards-xs gap-y-5 grid-rows-screen-small-pricing-cards mb-2;
}

.card {
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.1), 0 6px 20px -3px rgba(0, 0, 0, 0.2);
    &__header {
        @apply font-bold text-blueGray-600 text-card-header;
    }
    &--big {
        max-width: 40rem;
    }
    &--row {
        @apply sm:flex-row;
    }
    @apply max-w-lg relative flex flex-col min-w-0 break-words w-full rounded-lg bg-white;
}

.main-container {
    @apply grid mx-auto px-4 h-full gap-y-7 py-11 justify-items-center;
}

.thank-you-container {
    height: 75vh;
    @apply flex py-48 px-4 justify-center;
}

.form-submit-button {
    width: 175px;
    @apply flex justify-center font-bold bg-primary-600 text-white font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none;
}

.page-container {
    @apply flex w-full justify-center items-center flex-1;
}

.profile-container {
    @apply flex w-full justify-center items-center;
}

.custom-container {
    max-width: 1290px;
}

.nav-item {
    &:hover {
        @apply text-blue-500;
    }
    @apply block inline-block mr-7 font-bold;
}

.profile-content {
    @apply flex flex-col;
    //grid-template-columns: max-content min-content;
    //@apply grid text-22 mt-3 gap-x-3.5 gap-y-3.5;
}
.sweet-gw {
    &:focus {
        box-shadow: none !important;
    }

    &__deny {
        @apply bg-red-600 #{!important};
        &:hover {
            @apply bg-red-500 #{!important};
        }
    }

    &__confirm {
        @apply bg-primary-600 #{!important};
        &:hover {
            @apply bg-primary-500 #{!important};
        }
    }
}

.spinner {
    @apply animate-spin h-5 w-5;
}

.premium-button {
    @apply text-base
  font-bold
  text-white
  bg-gradient-to-r
  from-blue-premium-500
  to-violet-premium-500
  font-bold
  px-6
  py-3.5
  rounded
  shadow
  hover:shadow-lg
  outline-none
  focus:outline-none
  ease-linear
  transition-all
  duration-150 #{!important};
}

.coming-soon-badge {
    @apply inline-flex
  items-center
  justify-center
  px-2
  py-1
  text-xs
  text-orange-500
  font-bold
  leading-none
  bg-gray-400
  rounded-full;
}

.social-media {
    margin-top: 1rem;
    @apply text-center sm:mt-0 #{!important};
    &__icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        a {
            @apply mt-3;
        }
        a i {
            transition: all 0.2s ease-out;
            @apply text-primary-600;
            &:hover {
                transform: translateY(-5px);
            }
        }
    }
}

.blue-box-shadow {
    border-radius: 4px;
    //box-shadow: 0 4px 6px 0px rgba(#0069b4ff,0.3), 0 4px 6px 0px rgba(#0069b4ff,0.3);
    //box-shadow: 0px 4px 6px #0069B458;
    box-shadow: 0px 4px 6px rgba(0, 105, 180, 0.3);
}

.form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    color: #0069b4ff;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: 0.25rem;
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

@media not print {
    .form-checkbox::-ms-check {
        border-width: 1px;
        color: transparent;
        background: inherit;
        border-color: inherit;
        border-radius: inherit;
    }
}

.form-checkbox:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    border-color: #63b3ed;
}
